import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import BlogRoll from '../components/BlogRollWithLimit';
import { HTMLContent } from '../components/Content';

export const IndexPageTemplate = ({ heading, description, content }) => (
	<>
		<Hero />
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column is-10 is-offset-1">
						<div className="content">
							<div className="tile">
								<h2 className="title is-size-1">{heading}</h2>
							</div>
							<div className="tile">
								<h3 className="subtitle is-size-5">{description}</h3>
							</div>
							<div className="columns">
								<div className="column is-12">
									<HTMLContent content={content} />
								</div>
							</div>
							<div className="columns">
								<div className="column is-12">
									<h3 className="has-text-weight-semibold is-size-3">
										Artiklar
									</h3>
									<BlogRoll />
									<div className="column is-12 has-text-centered">
										<Link className="btn" to="/artiklar">
											Läs mer
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);

IndexPageTemplate.propTypes = {
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	description: PropTypes.string,
	content: PropTypes.node.isRequired,
};

const IndexPage = ({ data }) => {
	const { frontmatter, html } = data.markdownRemark;
	return (
		<Layout>
			<IndexPageTemplate
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				description={frontmatter.description}
				content={html}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			html
			frontmatter {
				title
				heading
				subheading
				description
			}
		}
	}
`;
